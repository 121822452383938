import './Filters.css';
import filter1 from "../assets/img/filter1.png";
import filter2 from "../assets/img/filter2.png";
import filter3 from "../assets/img/filter3.png";
import filter4 from "../assets/img/filter4.png";

function Filters() {
  return (
    <div className="filterwrapper">
        <img src={filter1} />
        <img src={filter2} />
        <img src={filter3} />
        <img src={filter4} />
    </div>
  );
}

export default Filters;

import './Timeline.css';
import React, { useRef, useState, useEffect } from 'react';
import slider from '../assets/img/slider.svg';
import addIcon from '../assets/img/addIcon.png';
import Draggable from 'react-draggable';
import Modal from 'react-modal';
import axios from "axios";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import playBtn from '../assets/img/playBtn.png';
import pauseBtn from '../assets/img/pauseBtn.png';

function CommentDot(props) {
    let style={left: props.xpos};
    return (<div className="commentDot" style={style}>
        <div className="marker"></div>
        <div className="comment"><h2>{props.name}</h2><p>{props.text}</p></div>
    </div>);
}

function StatusIndicator(props) {
    return (
        <div className="statusindicator">
            <div className="greendot"></div>
            <span className="statustextVal">{props.latency} ms</span><span className="statustext">latency</span>
        </div>
    );
}

function Placeholder(props) {
    return (
        <div className="placeholder" onClick={props.openModal}>
            <div className="placeholdercontainer">
                <img src={addIcon} /><span>Section</span>
            </div>
        </div>
    );
}

function VideoSection(props) {
    return (
        <div className="placeholder" onClick={props.openModal}>
            <div className="placeholdercontainer">
                <span>{props.name}</span>
            </div>
        </div>
    );
}

function VideoTimeline(props) {
    let res = [];

    for (let section of props.sections) {
        res.push(<VideoSection name={section}/>);
    }

    return (
        <>
        {res}
        </>
    );    
}

function ToAddGallery(props) {
    let res = [];
    let tmpsections = props.sections;

    for (let file of props.files) {
        res.push(<p onClick={() => {tmpsections.push(file); props.closeModal()}}>{file}</p>)
    }

    props.setSections(tmpsections);

    return (
        <>
        {res}
        </>
    );
}

function ScrollBar(props) {
    let moveTime = (e) => {
        let currentTargetRect = e.currentTarget.getBoundingClientRect();
        const event_offsetX = e.pageX - currentTargetRect.left;
        props.setTime(Math.round(event_offsetX / 50));
    };

    // load all comments

    return (
        <div className="scrollbar" onClick={(e) => moveTime(e)}>
            <CommentDot name="Ari Dulchinos" xpos={200} text="The transition is too fast!" />
            <CommentDot name="Max von Wolff" xpos={300} text="Great work, LOVE this scene. Perhaps turn up clarity even a bit more!" />
        </div>
    )
}

function Timeline(props) {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [toAdd, setToAdd] = React.useState([]);
    const [sections, setSections] = React.useState([]);
    const [scrollbarWidth, setScrollbarWidth] = React.useState(0);

    console.log(props.time);
    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        const formData = new FormData();
        formData.append("pid", "demo");
        
        axios.post('https://server.ozone.tech:5000/showfiles', formData).then(res => {
            setToAdd(res.data.files);
        })
    }

    function closeModal() {
        setIsOpen(false);
    }

    let dragUpdate = (e) => {
        let currentTargetRect = document.getElementsByClassName("scrollbar")[0].getBoundingClientRect();
        const event_offsetX = e.pageX - currentTargetRect.left;
        props.setTime(Math.round(event_offsetX / 50));
    }

    React.useEffect(() => {
        setScrollbarWidth(document.getElementsByClassName("scrollbar")[0].clientWidth);
        console.log("Scrollbar width: " + document.getElementsByClassName("scrollbar")[0].clientWidth)
    });

    let toggleBtn = () => {
        if (props.playcontrol === "pause") props.setPlaycontrol("play");
        if (props.playcontrol === "play") props.setPlaycontrol("pause");
      };
    

    return (
    <div className="view timeline">
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Video selector"
            className="modal"
            overlayClassName="modalOverlay"
            shouldFocusAfterRender={false}
        >
        <h2>Select footage</h2>
        <ToAddGallery files={toAdd} sections={sections} setSections={setSections} closeModal={closeModal} />

        </Modal>

        <h2>Timeline</h2>
        <div className="playpause" onClick={toggleBtn}>
            {props.playcontrol === "pause" && <img src={playBtn} />}
            {props.playcontrol === "play" && <img src={pauseBtn} />}
        </div>
        <StatusIndicator latency={props.latency}/>
        <ScrollBar setTime={props.setTime}/>
        
        <div className="wrapper">
            <VideoTimeline sections={sections} />
            <Placeholder openModal={openModal}/>
        </div>
        
        <Draggable
            axis="x"
            bounds={{left: 0, right: scrollbarWidth - 20, top: 0, bottom: 0}}
            defaultPosition={{x: 0, y: 0}}
            scale={1}
            position={{x: props.time * 50, y: 0}}
            onDrag={dragUpdate}>
            <div className="slider"><img src={slider} alt="slider" className="sliderImg" onDragStart={e => e.preventDefault()}/></div>
        </Draggable>
    </div>
    );
}

export default Timeline;

import './UploadView.css';
import {useDropzone} from 'react-dropzone';
import React, {useCallback} from 'react';
import axios from "axios";
import mediapreviewImg from "../assets/img/mediapreview.png";
import { useDrag } from 'react-dnd';

const ItemTypes = {
  BOX: 'box',
}

function MediaPreview(props) {
  const style = {
    border: '1px dashed gray',
    backgroundColor: 'white',
    padding: '0.5rem 1rem',
    marginRight: '1.5rem',
    marginBottom: '1.5rem',
    cursor: 'move',
    float: 'left',
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'box',
    item: 'Box',
    end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
            alert(`You dropped a box into the timeline!`);
        }
    },
    collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
    }),
  }));

  let res = [];
  
  for (let item of props.media) {
    res.push(
      <div ref={drag} role="Box" data-testid={`box-box`}>
          <div className="mediaitem" onClick={props.openModal}>
                  <img src={mediapreviewImg} />
                    <div className="mediaitemInfo">
                      <p>{item.filename}</p>
                    </div>
              </div>
        </div>);
  }
  
  return (<div className="mediaitems"><div className="mediaitemsScroll">{res}</div></div>);
}

function UploadView() {
  let [media, setMedia] = React.useState([]);

  React.useEffect(() => {
    // Get video files from server
    const formData = new FormData();
    formData.append("pid", "demo");
    
    axios.post('https://server.ozone.tech:5000/showfiles', formData).then(res => {
      let mediaObjs = [];
      for (let f of res.data.files) {
        mediaObjs.push({filename: f});
      }
        setMedia(mediaObjs);
    })
  }, []);

  const onDrop = useCallback(acceptedFiles => {
      acceptedFiles.forEach((file) => {
          const reader = new FileReader()
    
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = () => {
            const binaryStr = reader.result;
            // convert uploaded file to blob
            const blob = new Blob([new Uint8Array(binaryStr)], {type: file.type });
            console.log(file.name);
            console.log(blob);
            // Send file to server
            const formData = new FormData();
            formData.append("fileData", blob, file.name);
            formData.append("fileName", file.name);
            formData.append("pid", "demo");

            axios.post('https://server.ozone.tech:5000/upload', formData).then(res => {
              console.log(res);
            })
          }
          reader.readAsArrayBuffer(file)
        })
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div className="view">
        <h2>Media Browser</h2>

        {media.length !== 0 && <><MediaPreview media={media}/><div className="draginInvisible" {...getRootProps()}>
            <input {...getInputProps()} />
            </div></>}

        {media.length === 0 && <div className="dragin" {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                <h2>Drop the files here ...</h2> :
                <h2>Drag files in here to import!</h2>
            }
        </div>}
    </div>
  );
}

export default UploadView;

import './Viewport.css';
import React, { useRef, useState, useEffect } from 'react';
import axios from "axios";

function Viewport(props) {
  let [source, setSource] = React.useState("");

  React.useEffect(() => {
    // Fetch image from server
    const formData = new FormData();
    formData.append("frame_num", Math.round(props.time * 25));
    
    let startDate = new Date();
    axios.post('https://server.ozone.tech:5000/rendertestframe2', formData,
      { responseType: 'blob' },
    )
    .then(response => {
      setSource(URL.createObjectURL(response.data));

      let endDate = new Date();
      props.setLatency(endDate - startDate);
    });
  }, [props.time]);

  return (
    <>
    <div className="viewport">
      {source !== "" && <img src={source} className="viewportImg" />}
    </div>
    
    </>
  );
}

export default Viewport;

import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import UploadView from './components/UploadView';
import Timeline from './components/Timeline';
import Viewport from './components/Viewport';
import Filters from './components/Filters';
import React, { useRef, useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

function App() {
  let [playcontrol, setPlaycontrol] = React.useState("pause");
  let [time, setTime] = React.useState(0.0); // seconds 
  let [latency, setLatency] = React.useState(-1); // ms 
  
  let callback = () => {
    if (playcontrol === "play") {
      setTime(time + 0.1);
    }
  };
  let delay = 40;
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);

  let onDragEnd = () => {};

  return (
    <div className="App">
      <DndProvider backend={HTML5Backend}>
        <Header />
        <Viewport time={time} playcontrol={playcontrol} setPlaycontrol={setPlaycontrol} setLatency={setLatency} />
        <Filters />
        <div className="controls">
          <UploadView />
          <Timeline playcontrol={playcontrol} setPlaycontrol={setPlaycontrol} time={time} setTime={setTime} latency={latency}/>
        </div>
      </DndProvider>
    </div>
  );
}

export default App;
import './Header.css';
import logo from "../assets/img/logo.png";

function UserIcon(props) {
  return (
    <a className="userIcon" onDragOver={() => alert("test")}>
      <div>
        {props.letter}
      </div>
    </a>
  );
}

function HeaderButton(props) {
  return (
    <a className="button" onClick={props.action}>
      <div>
        {props.text}
      </div>
    </a>
  );
}

function Header() {

  // Actions
  let download = () => {
    alert("Downloading coming soon");
  };
  let share = () => {
    alert("Sharing coming soon");
  };

  return (
    <div className="header">
        <div className="logowrapper">
            <img src={logo}></img>
        </div>
        <span>Ozone</span>
        
        <HeaderButton text="Export" action={download} />

        <div className="userIcons">
          <HeaderButton text="Share" action={share} />
          <UserIcon letter="M" />
          <UserIcon letter="A" />
        </div>
    </div>
  );
}

export default Header;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./assets/fonts/SF-Pro.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'SF-Pro';\n  src: local('SF-Pro'), url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('truetype');\n}\n\n\n.App {\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  background-color: #0a050e;\n}\n\n.controls {\n  width: 100%;\n  position: absolute;\n  bottom: 0px;\n  left: 0px;\n}", "",{"version":3,"sources":["webpack://src/App.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gFAAuE;AACzE;;;AAGA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,SAAS;AACX","sourcesContent":["@font-face {\n  font-family: 'SF-Pro';\n  src: local('SF-Pro'), url(./assets/fonts/SF-Pro.ttf) format('truetype');\n}\n\n\n.App {\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  background-color: #0a050e;\n}\n\n.controls {\n  width: 100%;\n  position: absolute;\n  bottom: 0px;\n  left: 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
